<template>
  <div class="line inline-block">
    <el-button type="text" :loading="loading" size="small" @click="to"> 编辑 </el-button>
    <EditForm
      ref="form"
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :formLoading.sync="loading"
      :isAdd="false"
      :data="data"
      :title="title"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import EditForm from './batchEdit/editForm'
import { DOMESTIC_FORMWORK } from '@/utils/constant'
import { commonEheaderMixin } from '@/mixins'
import cloneDeep from 'lodash/cloneDeep'

export default {
  mixins: [commonEheaderMixin],
  components: { EditForm },
  props: {
    row: {
      type: Object,
      required: true
    },

    tableData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: false
    }
  },
  computed: {
    title() {
      return this.$route.query.category == DOMESTIC_FORMWORK ? '编辑省份/地区' : '编辑国家/地区'
    }
  },
  methods: {
    async to() {
      this.loading = true
      const { countryId } = this.row
      const detail = cloneDeep(this.tableData).filter(({ countryId: id }) => id === countryId)
      this.data = this.formatResult(detail)
      this.$nextTick(() => {
        this.showDialog()
      })
    },

    formatResult(results) {
      const tmpObj = {}
      results.map((item) => {
        const { countryName } = item
        if (!tmpObj[countryName]) {
          tmpObj[countryName] = []
        }
        tmpObj[countryName].push(item)
      })
      return tmpObj
    }
  }
}
</script>
